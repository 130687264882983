export const siteName = "Apaydın Sanal Market";
export const siteNameUpper = "APAYDIN SANAL MARKET";
export const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=com.zdc.android.apaydin";
export const appleStoreUrl =
  "https://apps.apple.com/tr/app/apayd%C4%B1n-sanal-market/id1510859311";
export const siteMailList = [
  {
    mail: "info@apaydinsupermarket.com",
    subject: "Bize Mail Bırakın",
  },
];
export const sitePhoneList = [
  { phone: "0 (536) 850 36 41", info: "" },
  // { phone: "0 (555) 192 54 01", info: "(Korucuk Şubesi)" }, // ikinci telefon eklenebilir.
];
export const eklenenMiktarKatsayisi = 0.5;
/* ============================================================================================== */
/*                            appstora yonlendirme mesaji ile ilgili                              */
/* ============================================================================================== */
export const askPlatformMessage = true; // yonlendirme sorulacak mi
export const askPlatformCounter = 5; // her kac giriste sorulacak (0 ve 1 icin surekli sorar)
export const useAskNeverButton = false; // bir daha sorma butonu aktif mi
/* ============================================================================================== */
/*                                  kategori side bar ile ilgili                                  */
/* ============================================================================================== */
export const categorySideBarType = 1; //1 yeni versiyon A101 ornegi, degilse orjinal versiyon
/* ============================================================================================== */
/*                                        harita ile ilgili                                       */
/* ============================================================================================== */
export const mapCoordinate = {
  lat: 40.765743,
  lng: 29.877501,
};
export const mapZoom = 12;
